import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import og from '../../assets/og-image.jpg'

const MetaHeaders = ({ title, description, customOgImage }) => (
    <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={customOgImage || og} />
    </Head>
)

MetaHeaders.defaultProps = {
    customOgImage: null,
}

MetaHeaders.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    customOgImage: PropTypes.string,
}

export default MetaHeaders
