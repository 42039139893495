import { createClient } from 'contentful'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const client = createClient({
    environment: publicRuntimeConfig.CONTENTFUL_ENVIRONMENT,
    space: publicRuntimeConfig.CONTENTFUL_SPACE_ID,
    accessToken: publicRuntimeConfig.CONTENTFUL_DELIVERY_ACCESS_TOKEN,
})

export default client
